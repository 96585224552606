import { defineStore } from 'pinia'
import type { ICart } from '~/types/models/cart.interface'

export const useCartStore = defineStore('cart', {
	state: () =>
		({
			cart: null,
		} as { cart: ICart | null }),
	actions: {
		async fetchCart() {
			const { $api } = useNuxtApp()

			try {
				const cart = await $api<ICart>('/cart')

				this.cart = cart
			} catch (error) {
				console.error(error)
			}
		},
		setCart(cart: ICart) {
			this.cart = cart
		},
	},
})
