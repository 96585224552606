import { useApi } from '~/composables/useApi'
import type { IUser } from '~/types/models/user.interface'

export default defineNuxtRouteMiddleware(async () => {
	const store = useUserStore()
	try {
		const user = await useApi<IUser>('/auth/me')
		const { $identifyUser } = useNuxtApp()

		if (!import.meta.server) {
			$identifyUser(user)
		}

		store.login(user)
	} catch (error) {
		// unauthenticated
	}
})
