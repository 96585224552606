import { useApi } from '~/composables/useApi'
import type { ICart } from '~/types/models/cart.interface'

export default defineNuxtRouteMiddleware(async () => {
	const store = useCartStore()
	try {
		const cart = await useApi<ICart>('/cart')

		if (cart) {
			store.setCart(cart)
		}
	} catch (error) {
		// unauthenticated
	}
})
