import posthog from 'posthog-js'
import type { IUser } from '~/types/models/user.interface'

export default defineNuxtPlugin(() => {
	const config = useRuntimeConfig()

	const isEnabled = config.public.enablePosthog
	let posthogInstance = null as any

	if (isEnabled) {
		posthogInstance = posthog.init(config.public.posthogToken, {
			api_host: 'https://us.i.posthog.com',
			person_profiles: 'identified_only',
		})
	}

	const captureEvent = (event: string, metadata?: any) => {
		if (posthogInstance) {
			posthogInstance.capture(event, metadata)
		}
	}

	const identifyUser = (user: IUser) => {
		if (!posthogInstance) {
			return
		}

		const userData = {
			email: user.email,
			role: user.role,
			createdAt: user.createdAt,
		}

		posthogInstance.identify(user._id, userData)
	}

	if (posthogInstance) {
		const router = useRouter()

		router.afterEach(to => {
			posthogInstance.capture('$pageview', {
				$current_url: to.fullPath,
				$referrer: document.referrer,
			})
		})
	}

	return {
		provide: {
			captureEvent,
			identifyUser,
		},
	}
})
