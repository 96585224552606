<template>
	<div class="flex items-center justify-center flex-col bg-zinc-400 w-12 h-12 rounded-full" v-if="!user?.avatar">
		<img src="/images/logo-black.svg" alt="logo" class="text-black w-2/3 opacity-40" />
	</div>
	<img v-if="user?.avatar" :src="user?.avatar" alt="User avatar" :class="computedAvatarClass" />
</template>

<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'

const { user } = useUserStore()
const attrs = useAttrs()
const props = defineProps<{
	avatarClass?: string
}>()

const firstName = user?.personalInfo?.firstName?.[0] || ''
const lastName = user?.personalInfo?.lastName?.[0] || ''
const initials = `${firstName}${lastName}`

const computedClass = computed(() => {
	return twMerge(
		'flex items-center justify-center text-white w-12 h-12 rounded-full bg-zinc-600 uppercase',
		attrs.class as string
	)
})

const computedAvatarClass = computed(() => {
	return twMerge('w-12 h-12 rounded-full object-cover', props.avatarClass as string)
})
</script>
