import type { UserLanguages } from '../language.interface'

export enum UserRole {
	ADMIN = 'admin',
	STREAMER = 'streamer',
	USER = 'user',
}

export enum StripeConnectStatus {
	PENDING = 'pending',
	ACTIVE = 'active',
}

export enum StripeRequirementStatus {
	MISSING_DETAILS = 'missing_details',
	MISSING_CHARGES = 'missing_charges',
	MISSING_PAYOUTS = 'missing_payouts',
}

export enum UserAssets {
	MUG = 'mug',
	T_SHIRT = 'tShirt',
	KEYCHAIN = 'keychain',
}

export interface SocialMediaLinks {
	instagram: string | null
	twitter: string | null
	youtube: string | null
	tiktok: string | null
	twitch: string | null
}

export interface IUser extends Document {
	_id: string
	avatar: string
	email: string
	stripeConnectStatus: StripeConnectStatus
	stripeRequirementStatus: StripeRequirementStatus[]
	phone?: string
	displayName?: string
	role: UserRole
	slug: string
	assets: Record<UserAssets, string>
	stripeAccountId?: string
	commissionPercentage: number
	updatedAt: Date
	createdAt: Date
	deactivatedAt?: Date
	userName?: string
	address: {
		street?: string
		city?: string
		state?: string
		zipCode?: string
		country?: string
		complement?: string
		number?: string
	}
	personalInfo: {
		firstName: string
		lastName?: string
		birthdate?: Date
		phone?: string
		document: {
			type?: string
			number?: string
			country?: string
		}
	}
	config: {
		lang: UserLanguages
		location: string
	}
	socialMedia?: SocialMediaLinks
}
