import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import authenticate_45user_45global from "/usr/src/app/middleware/authenticate-user.global.ts";
import populate_45cart_45global from "/usr/src/app/middleware/populate-cart.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authenticate_45user_45global,
  populate_45cart_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "authorize-admin": () => import("/usr/src/app/middleware/authorize-admin.ts"),
  "authorize-authenticated": () => import("/usr/src/app/middleware/authorize-authenticated.ts"),
  "authorize-guest": () => import("/usr/src/app/middleware/authorize-guest.ts"),
  "authorize-streamer": () => import("/usr/src/app/middleware/authorize-streamer.ts"),
  "authorize-user": () => import("/usr/src/app/middleware/authorize-user.ts")
}